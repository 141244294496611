import useScript from './useScript';
import { useEffect } from 'react';
import axios from 'axios';

// v1.1.3
const access_key = '0fc650b21d1cbd21bb2630d4336c283c';

async function createDrmPlayer(id, playUrl, direction) {
  /**
   * @TODO Server에 구현해야하는 부분
   */
  console.log('createDrmPlayer');
  // https://dan24-ncp.edge.naverncp.com
  let response;

  try {
    response = await axios.post('https://dan24-ncp.edge.naverncp.com/token', {
      id: direction === 'vertical' ? 'dan24!2323' : 'dan24Hz!2323',
      playUrl,
    });

    console.log('Response Data:', response.data);

    window.ncplayerDRM(id, {
      playlist: [
        {
          drm: response.data.drm,
        },
      ],
      autostart: true,
      muted: false,
      keyboardShortcut: true,
      controls: true,
      ui: 'all',
      controlBtn: {
        play: true,
        fullscreen: true,
        volume: true,
        times: true,
        pictureInPicture: true,
        subtitle: false,
        setting: true,
      },
      progressBarColor: '#4299f5',
      controlActiveTime: 3000,
      startMutedInfoNotVisible: false,
      playRateSetting: [0.5, 0.75, 1, 1.5, 2],
      aspectRatio: direction === 'vertical' ? '9/16' : '16/9',
      objectFit: 'cover',
      seekingPreview: true,
      autoPause: true,
      repeat: false,
      lowLatencyMode: true,
      lang: 'auto',
      touchGestures: true,
      descriptionNotVisible: false,
      visibleWatermark: false,
    });
  } catch (err) {
    console.log(err);
  }
}

const url = `https://player.vpe.naverncp.com/ncplayer.1.1.3.js?access_key=${access_key}`;

function VpePlayer({ playUrl, direction }) {
  let status = useScript(url);
  useEffect(() => {
    if (status === 'ready') {
      console.log('ready', url);
      createDrmPlayer('drm', playUrl, direction);
    } else {
      console.log('not ready', url);
    }
  }, [status]);

  return <div style={{ width: '100%', height: '100%' }} id="drm"></div>;
}
export default VpePlayer;
