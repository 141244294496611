import React, { useEffect, useRef } from 'react';
import * as shaka from 'shaka-player/dist/shaka-player.ui';
import 'shaka-player/dist/controls.css';

const ShakaPlayer = ({ dashUrl, hlsUrl, direction }) => {
  const videoRef = useRef(null);
  const videoContainer = useRef(null);
  const playerRef = useRef(null);

  useEffect(() => {
    const isSafari = videoRef.current.canPlayType(
      'application/vnd.apple.mpegurl'
    );
    if (videoRef.current) {
      playerRef.current = new shaka.Player();
      playerRef.current.attach(videoRef.current);
      const ui = new shaka.ui.Overlay(
        playerRef.current,
        videoContainer.current,
        videoRef.current
      );
      ui.getControls();
      playerRef.current.addEventListener('error', onErrorEvent);
      playerRef.current.load(isSafari ? hlsUrl : dashUrl).catch(onError);

      return () => {
        if (playerRef.current) {
          playerRef.current.destroy();
        }
      };
    }
  }, [dashUrl, hlsUrl]);

  function onErrorEvent(event) {
    onError(event.detail);
  }

  function onError(error) {
    console.error('Error code', error.code, 'object', error);
  }

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        aspectRatio: direction === 'vertical' ? '9/16' : '16/9',
      }}
      ref={videoContainer}
    >
      <video
        ref={videoRef}
        style={{ objectFit: 'cover' }}
        autoPlay
        muted
        width="100%"
        height="100%"
      />
    </div>
  );
};

export default ShakaPlayer;
