import React from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';

const Header = ({ url }) => {

  return (
    <AppBar sx={{background: 'black'}} position="sticky">
      <Toolbar>
        <Box
          component="img"
          src={url}
          sx={{ width: 'auto', height: '144px', margin: '22.5px auto' }}
        />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
