import DesktopMobileDemoPage from './DesktopMobileDemoPage';
import MobileDemoPage from './MobileDemoPage';
import { isMobile } from 'react-device-detect';
import { Stack } from '@mui/material';

export default function VerticalPage() {
  const className= isMobile ? 'is-mobile' : ''

  return (
    <Stack
      id="app"
      className={className}
      alignItems="center"
      justifyContent="center"
    >
      {
        !isMobile
        ? <DesktopMobileDemoPage/>
        : <MobileDemoPage />
      }
    </Stack>
  );
}
