import DesktopDemoPage from './DesktopDemoPage';
import InfoBox from './infoBox';
import { Stack } from '@mui/material';
import './base.css';

export default function HorizontalPage() {
  return (
    <Stack
      id="app"
      alignItems="center"
      justifyContent="center"
      sx={{ marginTop: '1rem' }}>
      <DesktopDemoPage direction={'horizontal'} />
      {/* <InfoBox isMobile={false} /> */}
    </Stack>
  );
}
