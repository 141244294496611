import ShakaPlayer from './shakaPlayer';
import VpePlayer from './vpePlayer';
import Header from './header';
import { Box, Stack, Typography } from '@mui/material';
import './DesktopDemoPage.css';

function DesktopDemoPage({ direction }) {
  const drmPlayUrl =
    direction === 'vertical'
      ? 'https://qktqatti4863.edge.naverncp.com/live/video/ls-20241029185837-pbuDs'
      : 'https://qktqatti4863.edge.naverncp.com/live/video/ls-20240927160526-WFGRO';
  const nonDrmPlayUrl =
    direction === 'vertical'
      ? 'https://lytqbysa5335.edge.naverncp.com/live/video/ls-20241024211606-pCHLb'
      : 'https://dkbc1egd5416.edge.naverncp.com/live/video/ls-20241101211705-2Xcct';
  return (
    <Box sx={{ width: '100%', height: '100%' }}>
      <Header url="./drm-logo.svg"></Header>
      <Stack
        sx={{ marginLeft: '5%', marginRight: '5%' }}
        flexDirection="row"
        alignItems="stretch"
        justifyContent="center"
        spacing={'10px'}
      >
        <Box>
          <Typography sx={{ color: 'white', margin: '30px 0 20px 0' }}>
            <label className="title">
              <span className="title-text">DRM 적용 전</span>
            </label>
          </Typography>
          <Box sx={{ width: '45rem' }}>
            <ShakaPlayer
              dashUrl={`${nonDrmPlayUrl}/live.mpd`}
              hlsUrl={`${nonDrmPlayUrl}/playlist.m3u8`}
              direction={direction}
            />
          </Box>
        </Box>
        <Box className="box" sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography sx={{ color: 'white', margin: '30px 0 20px 0' }}>
            <label className="title">
              <span className="title-text">DRM 적용 후</span>
            </label>
          </Typography>
          <Box
            sx={{
              width: '45rem',
              minHeight: '200px',
              border: '5px solid #FF0000',
              flex: 1,
            }}
          >
            <VpePlayer playUrl={drmPlayUrl} direction={direction} />
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{ marginLeft: '5%', marginRight: '5%', marginTop: '80px' }}
        flexDirection="row"
        alignItems="start"
        justifyContent="center"
        spacing={'5%'}
      >
        <Box>
          <Box sx={{ width: '50rem', color: '#fff' }}>
            <ul className="info-box">
              <li>
                본 화면은{' '}
                <span className="light-text">B2B Prism Live Studio</span> 앱을
                통해 송출되고 있으며, <br />
                <span className="light-text">Live Station</span> 을 통해 실시간
                멀티 화질 변환과 DRM 패키징을 동시에 지원합니다.
              </li>
              <li style={{ marginTop: '32px' }}>
                DRM 에 대한 암호화는{' '}
                <span className="light-text">One Click Multi DRM</span> 을 통해
                이루어집니다. 
              </li>
              <li style={{ marginTop: '32px' }}>
                DRM 에 대한 영상은{' '}
                <span className="light-text">Video Player Enhancement</span> 를
                통해 확인하실 수 있습니다.
              </li>
            </ul>
          </Box>
        </Box>

        <Box className="box" sx={{ width: '40rem', color: '#fff' }}>
          <ul className="info-box">
            <li className="no-point red">
              ※ DRM 적용 시,영상을 캡쳐했을 때 검은화면으로 노출됩니다.
            </li>
          </ul>
        </Box>
      </Stack>
    </Box>
  );
}

export default DesktopDemoPage;
