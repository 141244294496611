import { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import Header from './header';
import { CssBaseline, GlobalStyles } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import VerticalPage from './verticalPage';
import HorizontalPage from './horizontalPage';

function App() {
  useEffect(() => {
    const appEl = document.getElementById('app')

    if (!appEl || isMobile) {
      return;
    }
    const originWidth = 1920;
    const originHeight = 1080;
    const originRatio = 16 / 9;

    const currentClientWidth = document.documentElement.clientWidth || document.body.clientWidth;
    const currentClientHeight = document.documentElement.clientHeight || document.body.clientHeight;
    const currentRatio = currentClientWidth / currentClientHeight;
    let scaleRatio = currentClientWidth / originWidth

    if (currentRatio > originRatio) {
      scaleRatio = currentClientHeight / originHeight
      appEl.style = `transform: scale(${scaleRatio}) translate(-50%,-50%);`
    } else {
      appEl.style = `transform: scale(${scaleRatio}) translate(-50%,-50%);`
    }
  }, [])


  return (
    <div>
      <CssBaseline />
      <GlobalStyles
        styles={{
          '*': {
            padding: 0,
            margin: 0,
          },
          body: {
            position: 'absolute',
            minHeight: '100vh',
            width: '100%',
            overflow: 'hidden',
            margin: 0,
            backgroundColor: '#000000',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          },
        }}
      />
      {/* <Header /> */}
      <Router>
        <Routes>
          <Route path="/vertical" element={<VerticalPage />} />
          <Route path="/horizontal" element={<HorizontalPage />} />
        </Routes>
        { !isMobile && <div className="footer-bar"></div> }
      </Router>
    </div>
  );
}

export default App;
