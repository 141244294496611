import ShakaPlayer from "./shakaPlayer";
import VpePlayer from "./vpePlayer";
import Header from "./header";
import { Box, Stack, Typography } from "@mui/material";
import "./DesktopDemoPage.css";

function DesktopMobileDemoPage() {
  const drmPlayUrl = "https://qktqatti4863.edge.naverncp.com/live/video/ls-20241029185837-pbuDs";
  const nonDrmPlayUrl = "https://lytqbysa5335.edge.naverncp.com/live/video/ls-20241024211606-pCHLb";
  const headerImg = "./live_streaming_logo.svg";

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      <Header url={headerImg}></Header>
      <Stack
        sx={{ height: "calc(100% - 189px)", marginLeft: "5%", marginRight: "5%" }}
        flexDirection="row"
        alignItems="center"
        justifyContent="start"
      >
        <Stack
          style={{ height: "100%", marginLeft: "2%", marginRight: "2%" }}
          flex={13}
          flexDirection="row"
          alignItems="center"
          justifyContent="start"
        >
          <Box sx={{ width: "47%", height: "100%", marginRight: "6%" }}>
            <Stack
              style={{ height: "100%" }}
              flexDirection="column"
              alignItems="center"
              justifyContent="start"
            >
              <Typography sx={{ width: "100%", color: "white", margin: "0 auto 22px" }}>
                <label className="title">
                  <span className="title-text">DRM 적용 전</span>
                </label>
              </Typography>
              <Box sx={{ width: "426px", height: "760px" }}>
                <ShakaPlayer
                  dashUrl={`${nonDrmPlayUrl}/live.mpd`}
                  hlsUrl={`${nonDrmPlayUrl}/playlist.m3u8`}
                  direction="vertical"
                />
              </Box>
            </Stack>
          </Box>
          <Box sx={{ width: "47%", height: "100%" }}>
            <Stack
              style={{ height: "100%" }}
              flexDirection="column"
              alignItems="center"
              justifyContent="start"
            >
              <Typography sx={{ width: "100%", color: "white", margin: "0 auto 22px" }}>
                <label className="title">
                  <span className="title-text">DRM 적용 후</span>
                </label>
              </Typography>
              <Box sx={{ width: "426px", height: "760px", border: "5px solid #FF0000", boxSizing: "border-box" }}>
                <VpePlayer playUrl={drmPlayUrl} direction="vertical" />
              </Box>
            </Stack>
          </Box>
        </Stack>
        <Stack
          style={{ height: "100%", marginLeft: "2%", marginRight: "2%", paddingTop: "68px" }}
          flex={10}
        >
          <Box sx={{ width: "100%", height: "760px" }}>
            <Stack
              sx={{ width: "100%", height: "100%" }}
              flexDirection="column"
              alignItems="start"
              justifyContent="start"
            >
              <Box
                component="img"
                src="./full_stack.svg"
                sx={{ maxHeight: "213px", marginTop: "120px" }}
              />
              <Box sx={{ width: "100%", marginTop: "1rem", color: "#fff" }}>
                <ul className="info-box">
                  <li>
                    본 화면은{" "}
                    <span className="light-text">B2B Prism Live Studio</span> 앱을
                    통해 송출되고 있으며, <br />
                    <span className="light-text">Live Station</span> 을 통해 실시간
                    멀티 화질 변환과 DRM 패키징을 동시에 지원합니다.
                  </li>
                  <li style={{ marginTop: '32px' }}>
                    DRM 에 대한 암호화는{" "}
                    <span className="light-text">One Click Multi DRM</span> 을 통해
                    이루어집니다.
                  </li>
                  <li style={{ marginTop: '32px' }}>
                    DRM 에 대한 영상은{" "}
                    <span className="light-text">Video Player Enhancement</span> 를
                    통해 확인하실 수 있습니다.
                  </li>
                </ul>
              </Box>
              <Box sx={{ width: "100%", flex: 1, position: "relative" }}>
                <p className="info-box red tip-text">
                  ※ DRM 적용 시,영상을 캡쳐했을 때 검은화면으로 노출됩니다.
                </p>
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Stack>
      <Box
        component="img"
        src="./dummy_QR.svg"
        sx={{ width: "93px", height: "93px", position: "absolute", right: "30px", bottom: "30px" }}
      />
    </Box>
  );
}

export default DesktopMobileDemoPage;
