import { useState, Fragment, useEffect } from 'react';
import ShakaPlayer from './shakaPlayer';
import VpePlayer from './vpePlayer';
import {
  Box,
  Stack,
  Switch,
  IconButton,
  Typography,
  styled,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';

function MobileDemoPage() {
  const [drmEnabled, setDrmEnabled] = useState(true);
  const [isShowMention, setShowMention] = useState(false);
  const [adjustSize, setAdjustSize] = useState(16 / 9);

  useEffect(() => {
    const deviceWindowWidth = window.innerWidth;
    const deviceWindowHeight = window.innerHeight;
    const deviceAspectRatio = deviceWindowHeight / deviceWindowWidth;

    if (deviceAspectRatio > 16 / 9) {
      setAdjustSize(deviceAspectRatio);
    } else {
      setAdjustSize(16 / 9);
    }
  }, []);

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 64,
    height: 32,
    padding: 0,
    '& .MuiSwitch-switchBase': {
      padding: 0,
      margin: 2,
      transitionDuration: '300ms',
      '&.Mui-checked': {
        transform: 'translateX(32px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          border: 0,
        },
        '&.Mui-disabled + .MuiSwitch-track': {
          opacity: 0.5,
        },
      },
      '&.Mui-focusVisible .MuiSwitch-thumb': {
        color: '#33cf4d',
        border: '6px solid #fff',
      },
      '&.Mui-disabled .MuiSwitch-thumb': {
        color:
          theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
      },
    },
    '& .MuiSwitch-thumb': {
      boxSizing: 'border-box',
      width: 28,
      height: 28,
    },
    '& .MuiSwitch-track': {
      borderRadius: 32 / 2,
      backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
      opacity: 1,
      transition: theme.transitions.create(['background-color'], {
        duration: 500,
      }),
    },
  }));

  return (
    <Fragment>
      <Stack
        sx={{
          width: '100vw',
          height: '100vh',
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'hidden',
          background: '#bebebe',
        }}
      >
        <Stack
          sx={{
            position: 'relative',
            width: '100%',
            paddingTop: `${adjustSize * 100}%`,
          }}
        >
          <Stack
            position="fixed"
            top="0"
            left="0"
            width="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            zIndex="888"
          >
            <Stack
	      width="100%"
              flexDirection="row"
              justifyContent="end"
              alignItems="center"
            >
	    <Typography
                padding=".1rem .5rem"
                margin="1rem .5rem"
                fontSize="12px"
                fontWeight="bolder"
                color="black"
              >
                DRM 더 알아보기
	         <IconButton
                  sx={{
                    padding: '0px',
                  }}
                  onClick={() => setShowMention(true)}
                >
                  <MoreVertIcon sx={{ color: 'black' }} />
                 </IconButton>
              </Typography>
            </Stack>
            <Stack
              width="100%"
	      justifyContent="center"
              alignItems="center"
            >
	      <Box
                component="img"
                src='./mobile-header.svg'
                sx={{ width: '80%', height: 'auto', margin: 'auto' }}
              /> 
	    </Stack>
          </Stack>
          <Stack
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '100%',
              height: '100%',
            }}
          >
            {drmEnabled && (
              <Box
                sx={{ width: '100%', height: '100%', background: '#bebebe' }}
              >
		<VpePlayer
                  playUrl={
                    'https://qktqatti4863.edge.naverncp.com/live/video/ls-20241029185837-pbuDs'
                  }
                  direction="vertical"
                />
              </Box>
            )}
            {!drmEnabled && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  background: '#bebebe',
                }}
              >
                <ShakaPlayer
                  dashUrl="https://lytqbysa5335.edge.naverncp.com/live/video/ls-20241024211606-pCHLb/live.mpd"
                  hlsUrl="https://lytqbysa5335.edge.naverncp.com/live/video/ls-20241024211606-pCHLb/playlist.m3u8"
                  direction="vertical"
                />
              </Box>
            )}
          </Stack>
          <Stack
            position="fixed"
            bottom="4vh"
            left="0"
            width="100%"
            justifyContent="center"
            alignItems="center"
          >
            <Typography fontWeight="bolder" color="white">
              {`OFF\u00A0\u00A0\u00A0ON`}
            </Typography>
            <IOSSwitch
              onChange={(_, checked) => {
                setDrmEnabled(checked);
              }}
              checked={drmEnabled}
              sx={{
                marginTop: '.5rem',
              }}
            ></IOSSwitch>
          </Stack>
        </Stack>
      </Stack>

      {isShowMention && (
        <Stack
          flexDirection="column"
          justifyContent="flex-start"
          alignItems="center"
          sx={{
            position: 'fixed',
            top: '0',
            left: '0',
            width: '100vw',
            height: '100vh',
            zIndex: '999',
            background: '#454545',
            padding: '2.5rem',
            paddingTop: '3.5rem',
            gap: '5vh',
          }}
        >
          <Stack alignItems="center" marginTop="-1vh">
            <Typography fontWeight="bolder" color="white">
              Mobile에서도
            </Typography>
            <Typography variant="h5" fontWeight="bolder" color="white">
              Multi DRM을 적용해보세요!
            </Typography>
          </Stack>
          <Box
            sx={{
              height: '5rem',
              width: '5rem',
            }}
          >
            <svg
              viewBox="0 0 130 115"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M19.167 19.1667V95.8333C19.167 98.375 20.1767 100.813 21.9739 102.61C23.7711 104.407 26.2087 105.417 28.7503 105.417H86.2503C88.792 105.417 91.2295 104.407 93.0268 102.61C94.824 100.813 95.8337 98.375 95.8337 95.8333V39.9721C95.8335 38.6954 95.5783 37.4316 95.0829 36.2549C94.5875 35.0782 93.8621 34.0124 92.9491 33.12L71.6741 12.3146C69.8837 10.564 67.4793 9.58365 64.9753 9.58334H28.7503C26.2087 9.58334 23.7711 10.593 21.9739 12.3902C20.1767 14.1875 19.167 16.625 19.167 19.1667Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M67.084 9.58334V28.75C67.084 31.2917 68.0937 33.7292 69.8909 35.5264C71.6881 37.3237 74.1257 38.3333 76.6673 38.3333H95.834"
                stroke="white"
                stroke-width="2"
                stroke-linejoin="round"
              />
              <path
                d="M128.375 83.8542C128.375 100.503 114.878 114 98.2288 114C81.5798 114 68.083 100.503 68.083 83.8542C68.083 67.2051 81.5798 53.7083 98.2288 53.7083C114.878 53.7083 128.375 67.2051 128.375 83.8542Z"
                fill="#454545"
                stroke="white"
                stroke-width="2"
              />
              <path
                d="M107.214 76.8264H105.716V73.6319C105.716 69.2236 102.362 65.6458 98.2292 65.6458C94.0964 65.6458 90.7422 69.2236 90.7422 73.6319V76.8264H89.2448C87.5977 76.8264 86.25 78.2639 86.25 80.0208V95.993C86.25 97.75 87.5977 99.1875 89.2448 99.1875H107.214C108.861 99.1875 110.208 97.75 110.208 95.993V80.0208C110.208 78.2639 108.861 76.8264 107.214 76.8264ZM93.737 76.8264V73.6319C93.737 70.9806 95.7435 68.8403 98.2292 68.8403C100.715 68.8403 102.721 70.9806 102.721 73.6319V76.8264H93.737Z"
                fill="white"
              />
              <path
                d="M66.3967 47.8155C68.2009 48.8771 68.2241 51.4789 66.439 52.5725L46.9702 64.4906C45.1313 65.6166 42.7673 64.3061 42.7482 62.1495L42.5394 38.6611C42.5203 36.5059 44.8606 35.1535 46.7192 36.2452L66.3967 47.8155Z"
                fill="white"
              />
            </svg>
          </Box>
          <Stack alignItems="center">
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              One Click Multi DRM은
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              온라인 교육, 콘서트 등 실시간 스트리밍 서비스의
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              디지털 콘텐츠 저작권을 보호해줍니다.
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              · 표준 DRM 시스템인
              <span className="linear-gradient1"> Widevine</span>,
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              <span className="linear-gradient2">FairPlay,PlayReady</span>를
              모두 지원하며,
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              동시 적용 가능합니다.
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              · <span className="linear-gradient3">Live Streaming </span>
              서비스에도
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              · Multi DRM 적용이 가능합니다.
            </Typography>
          </Stack>
          <Stack alignItems="center">
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              · 네이버 클라우드 플랫폼의
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              ·
              <span className="linear-gradient4">
                Live Station, VOD Station{' '}
              </span>
              서비스와
            </Typography>
            <Typography fontSize="0.8rem" fontWeight="bolder" color="white">
              · 연동하여 손쉽게 적용 가능합니다.
            </Typography>
          </Stack>

          <IconButton onClick={() => setShowMention(false)}>
            <span
              style={{ color: 'white', fontSize: '14px', fontWeight: 'bolder' }}
            >
              Close
            </span>
          </IconButton>
        </Stack>
      )}
    </Fragment>
  );
}
export default MobileDemoPage;
